export enum LoyaltyTier {
  GOLD = "gold",
  BRONZE = "bronze",
  BLUE = "blue",
  SILVER = "silver",
  PLATINUM = "platinum",
}

interface Colors {
  [key: string]: string;
}

export const tierColors: Colors = {
  gold: "#D4982F",
  bronze: "#A26009",
  blue: "#3281BA",
  silver: "#92969A",
  platinum: "#718896",
};

export const vectorColors: Colors = {
  static: "#F2A725",
  increasing: "#4CB260",
  decreasing: "#D70000",
};

import { LoyaltyVector } from "@wttb/shared";
import React from "react";

import { vectorColors } from "@/common/constants/loyalty";

interface IconProps {
  vector: LoyaltyVector;
  width?: string;
  height?: string;
}

const LoyaltyVectorIcon = ({ vector, width = "48px", height = "48px" }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {vector === LoyaltyVector.Static && (
        <React.Fragment>
          <circle cx="12" cy="12.002" r="12" fill="#FCEDD3" />
          <path
            d="M10.5 12.002h3M4.499 12.002h3M16.501 12.002h3"
            stroke={vectorColors[vector]}
            strokeLinecap="round"
          />
        </React.Fragment>
      )}
      {vector === LoyaltyVector.Increasing && (
        <React.Fragment>
          <circle cx="12" cy="12.002" r="12" fill="#EDF7EF" />
          <g clipPath="url(#8d04yjlyua)">
            <path
              d="M5.629 17.001a.62.62 0 0 1-.447-.197.715.715 0 0 1 0-.958l4.528-4.89a.594.594 0 0 1 .887 0l2.396 2.588 3.862-4.17h-2.17c-.346-.008-.629-.313-.629-.687 0-.374.283-.68.63-.68h3.678c.346 0 .63.306.63.68v4.429c0 .373-.284.679-.63.679-.346 0-.629-.306-.629-.68v-2.784l-4.301 4.646a.594.594 0 0 1-.887 0l-2.396-2.588-4.076 4.408a.595.595 0 0 1-.446.197v.007z"
              fill={vectorColors[vector]}
            />
          </g>
          <defs>
            <clipPath id="8d04yjlyua">
              <path fill="#fff" transform="translate(5 8.002)" d="M0 0h14v9H0z" />
            </clipPath>
          </defs>
        </React.Fragment>
      )}
      {vector === LoyaltyVector.Decreasing && (
        <React.Fragment>
          <ellipse
            cx="11.993"
            cy="12"
            rx="11.993"
            ry="12"
            transform="matrix(-1 0 0 1 24 .002)"
            fill="#EBECEC"
          />
          <g clipPath="url(#0jl2i4mwwa)">
            <path
              d="M18.375 17.001a.62.62 0 0 0 .446-.197.715.715 0 0 0 0-.958l-4.526-4.89a.594.594 0 0 0-.886 0l-2.395 2.588-3.86-4.17h2.169c.346-.008.629-.313.629-.687 0-.374-.283-.68-.63-.68H5.647c-.346 0-.629.306-.629.68v4.429c0 .373.283.679.629.679.345 0 .628-.306.628-.68v-2.784l4.3 4.646a.594.594 0 0 0 .886 0l2.395-2.588 4.073 4.408a.594.594 0 0 0 .447.197v.007z"
              fill="#989DA0"
            />
          </g>
          <ellipse
            cx="11.993"
            cy="12"
            rx="11.993"
            ry="12"
            transform="matrix(1 0 0 -1 0 24.002)"
            fill="#FBE5E5"
          />
          <g clipPath="url(#99lo7f29zb)">
            <path
              d="M5.625 7.003a.62.62 0 0 0-.446.197.716.716 0 0 0 0 .958l4.526 4.89a.594.594 0 0 0 .886 0l2.395-2.588 3.86 4.17h-2.169c-.346.008-.628.313-.628.687 0 .373.282.68.628.68h3.677c.346 0 .629-.307.629-.68v-4.429c0-.373-.283-.68-.629-.68-.345 0-.628.307-.628.68v2.785l-4.3-4.646a.594.594 0 0 0-.886 0l-2.395 2.588-4.073-4.408a.594.594 0 0 0-.447-.197v-.007z"
              fill={vectorColors[vector]}
            />
          </g>
          <defs>
            <clipPath id="0jl2i4mwwa">
              <path fill="#fff" transform="matrix(-1 0 0 1 19.003 8.002)" d="M0 0h13.992v9H0z" />
            </clipPath>
            <clipPath id="99lo7f29zb">
              <path fill="#fff" transform="matrix(1 0 0 -1 4.997 16.002)" d="M0 0h13.992v9H0z" />
            </clipPath>
          </defs>
        </React.Fragment>
      )}
    </svg>
  );
};

export default LoyaltyVectorIcon;

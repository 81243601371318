"use client";

import { useSession } from "next-auth/react";

import VectorIcon from "@/common/icons/vector-icon";
import { useMe } from "../hooks/use-me";

export function LoayltyVector() {
  const session = useSession();
  const { data: customer } = useMe(session.data);

  if (!customer) return;

  const loyaltyVectorKey = customer?.custom?.fields.loyaltyVectorKey;

  return <VectorIcon vector={loyaltyVectorKey} width="24px" height="24px" />;
}

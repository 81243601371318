import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeImageWithAltTextSkeleton } from "./TypeImageWithAltText";

/**
 * Fields type definition for content type 'TypeExternalLink'
 * @name TypeExternalLinkFields
 * @type {TypeExternalLinkFields}
 * @memberof TypeExternalLink
 */
export interface TypeExternalLinkFields {
    /**
     * Field type definition for field 'name' (Name)
     * @name Name
     * @localized false
     * @summary The name to easily locate the link
     */
    name: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'menuTitle' (Menu title)
     * @name Menu title
     * @localized false
     * @summary Title to be used in the navigation menu
     */
    menuTitle?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'url' (URL)
     * @name URL
     * @localized false
     * @summary Full external URL like "https://www.trustpilot.com/review/wttb.co.uk"
     */
    url: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'icon' (Icon)
     * @name Icon
     * @localized false
     */
    icon?: EntryFieldTypes.AssetLink;
    /**
     * Field type definition for field 'icon_imageWithAltText' (Icon using imageWithAltText)
     * @name Icon using imageWithAltText
     * @localized false
     */
    icon_imageWithAltText?: EntryFieldTypes.EntryLink<TypeImageWithAltTextSkeleton>;
}

/**
 * Entry skeleton type definition for content type 'externalLink' (🌐 External link)
 * @name TypeExternalLinkSkeleton
 * @type {TypeExternalLinkSkeleton}
 * @author 0kZNfwixsbsTjcGzU3RIqE
 * @since 2024-06-20T12:04:55.942Z
 * @version 3
 */
export type TypeExternalLinkSkeleton = EntrySkeletonType<TypeExternalLinkFields, "externalLink">;
/**
 * Entry type definition for content type 'externalLink' (🌐 External link)
 * @name TypeExternalLink
 * @type {TypeExternalLink}
 * @author 0kZNfwixsbsTjcGzU3RIqE
 * @since 2024-06-20T12:04:55.942Z
 * @version 3
 */
export type TypeExternalLink<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeExternalLinkSkeleton, Modifiers, Locales>;

export function isTypeExternalLink<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeExternalLink<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'externalLink'
}

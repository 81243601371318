import(/* webpackMode: "eager" */ "/vercel/path0/apps/store/public/assets/images/logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/apps/store/src/app/[locale]/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SentryUserManager"] */ "/vercel/path0/apps/store/src/app/[locale]/sentry-user-manager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RouteChangeListener"] */ "/vercel/path0/apps/store/src/app/route-change-listener.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UIProvider"] */ "/vercel/path0/apps/store/src/common/contexts/ui.context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/vercel/path0/apps/store/src/common/layout/error-boundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/store/src/common/layout/header/auth-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderLoader"] */ "/vercel/path0/apps/store/src/common/layout/header/header-loader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileMenuButton"] */ "/vercel/path0/apps/store/src/common/layout/header/mobile-menu-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavItem"] */ "/vercel/path0/apps/store/src/common/layout/header/nav-item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileMenuHeader"] */ "/vercel/path0/apps/store/src/common/layout/mobile-navigation/mobile-menu-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileMenuLevels"] */ "/vercel/path0/apps/store/src/common/layout/mobile-navigation/mobile-menu-levels.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileNavigation"] */ "/vercel/path0/apps/store/src/common/layout/mobile-navigation/mobile-navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoayltyTier"] */ "/vercel/path0/apps/store/src/features/account/components/loyalty-tier.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoayltyVector"] */ "/vercel/path0/apps/store/src/features/account/components/loyalty-vector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/store/src/features/basket/components/cart-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartDrawer"] */ "/vercel/path0/apps/store/src/features/basket/components/cart-drawer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LivePreviewSubscriber"] */ "/vercel/path0/apps/store/src/features/contentful/components/live-preview-subscriber.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ManagedModal"] */ "/vercel/path0/apps/store/src/features/store/components/managed-modal.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/store/src/features/store/components/search/algolia-search.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/store/src/styles/scrollbar.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/store/src/styles/swiper-carousel.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/store/src/styles/custom-plugins.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/store/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@contentful+live-preview@2.11.1_react-dom@18.2.0_react@18.2.0/node_modules/@contentful/live-preview/dist/style.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.2.16_react@18.2.0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.2.16_react@18.2.0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.2.16_react@18.2.0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.1.1/node_modules/@vercel/analytics/dist/react/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.2/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.13.0_next@14.2.16_react@18.2.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.16_@babel+core@7.24.3_@opentelemetry+api@1.9.0_@playwright+test@1.45.2_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.16_@babel+core@7.24.3_@opentelemetry+api@1.9.0_@playwright+test@1.45.2_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.16_@babel+core@7.24.3_@opentelemetry+api@1.9.0_@playwright+test@1.45.2_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.16_@babel+core@7.24.3_@opentelemetry+api@1.9.0_@playwright+test@1.45.2_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.16_@babel+core@7.24.3_@opentelemetry+api@1.9.0_@playwright+test@1.45.2_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.16_@babel+core@7.24.3_@opentelemetry+api@1.9.0_@playwright+test@1.45.2_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../public/fonts/proxima-nova-regular.woff2\",\"weight\":\"400\"},{\"path\":\"../../../public/fonts/proxima-nova-semi-bold.woff2\",\"weight\":\"600\"}]}],\"variableName\":\"proximaNova\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/vercel/path0/node_modules/.pnpm/react-toastify@9.1.3_react-dom@18.2.0_react@18.2.0/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-toastify@9.1.3_react-dom@18.2.0_react@18.2.0/node_modules/react-toastify/dist/ReactToastify.css");

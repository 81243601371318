import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeCardRowComponentSkeleton } from "./TypeCardRowComponent";
import type { TypeCustomerServiceComponentSkeleton } from "./TypeCustomerServiceComponent";
import type { TypeFaqComponentSkeleton } from "./TypeFaqComponent";
import type { TypeFeaturedProductsComponentSkeleton } from "./TypeFeaturedProductsComponent";
import type { TypeHeroCarouselComponentSkeleton } from "./TypeHeroCarouselComponent";
import type { TypeImageWithAltTextSkeleton } from "./TypeImageWithAltText";
import type { TypeProductCardsComponentSkeleton } from "./TypeProductCardsComponent";
import type { TypeRichTextComponentSkeleton } from "./TypeRichTextComponent";
import type { TypeSeoSkeleton } from "./TypeSeo";
import type { TypeSubscriptionComponentSkeleton } from "./TypeSubscriptionComponent";
import type { TypeUspBarComponentSkeleton } from "./TypeUspBarComponent";

/**
 * Fields type definition for content type 'TypeHomePage'
 * @name TypeHomePageFields
 * @type {TypeHomePageFields}
 * @memberof TypeHomePage
 */
export interface TypeHomePageFields {
    /**
     * Field type definition for field 'title' (Title)
     * @name Title
     * @localized false
     */
    title?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'slug' (Slug)
     * @name Slug
     * @localized true
     */
    slug: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'content' (Content)
     * @name Content
     * @localized false
     */
    content?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeCardRowComponentSkeleton | TypeCustomerServiceComponentSkeleton | TypeFaqComponentSkeleton | TypeFeaturedProductsComponentSkeleton | TypeHeroCarouselComponentSkeleton | TypeProductCardsComponentSkeleton | TypeRichTextComponentSkeleton | TypeSubscriptionComponentSkeleton | TypeUspBarComponentSkeleton>>;
    /**
     * Field type definition for field 'image' (Image)
     * @name Image
     * @localized false
     * @summary Open Graph image
     */
    image?: EntryFieldTypes.AssetLink;
    /**
     * Field type definition for field 'seoMetadata' (SEO Metadata)
     * @name SEO Metadata
     * @localized false
     */
    seoMetadata?: EntryFieldTypes.EntryLink<TypeSeoSkeleton>;
    /**
     * Field type definition for field 'image_imageWithAltText' (Image using imageWithAltText)
     * @name Image using imageWithAltText
     * @localized false
     */
    image_imageWithAltText?: EntryFieldTypes.EntryLink<TypeImageWithAltTextSkeleton>;
}

/**
 * Entry skeleton type definition for content type 'homePage' (📄 Home Page)
 * @name TypeHomePageSkeleton
 * @type {TypeHomePageSkeleton}
 * @author 3wpLHpdye9A1HI6MK88Utd
 * @since 2023-09-27T11:17:29.522Z
 * @version 51
 */
export type TypeHomePageSkeleton = EntrySkeletonType<TypeHomePageFields, "homePage">;
/**
 * Entry type definition for content type 'homePage' (📄 Home Page)
 * @name TypeHomePage
 * @type {TypeHomePage}
 * @author 3wpLHpdye9A1HI6MK88Utd
 * @since 2023-09-27T11:17:29.522Z
 * @version 51
 */
export type TypeHomePage<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeHomePageSkeleton, Modifiers, Locales>;

export function isTypeHomePage<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeHomePage<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'homePage'
}

import Image from "next/image";
import { Highlight } from "react-instantsearch";

import { Link } from "@/common/components/link";
import { useDefaultPriceWithLoyalty, useFormattedPrice } from "@/features/product/hooks/use-price";
import { ROUTES } from "@/features/store/settings/routes";

type SearchProductProps = {
  item: any;
};

export function SearchProduct({ item }: SearchProductProps) {
  const { data: pricingData } = useDefaultPriceWithLoyalty({
    sku: item.objectID,
  });

  const price = useFormattedPrice(pricingData?.price);
  const discountedPrice = useFormattedPrice(pricingData?.discountTotal);
  return (
    <Link
      href={`${ROUTES.PRODUCT}/${item?.slug}`}
      className="group flex h-auto w-full items-center justify-start"
    >
      <div className="bg-grey-10 relative mr-4 flex size-24 shrink-0 cursor-pointer overflow-hidden rounded-md">
        <Image
          src={
            item?.mainImage ? `https:${item.mainImage}` : "/assets/placeholder/search-product.svg"
          }
          width={96}
          height={96}
          loading="eager"
          alt={item.title || "Product Image"}
          className="bg-grey-10 object-cover"
          sizes="96px"
        />
      </div>
      <div className="flex w-full flex-col overflow-hidden">
        <h3 className="mb-2 truncate text-sm">
          <Highlight attribute="title" hit={item} />
        </h3>
        <div className="text-sm font-semibold">
          {discountedPrice || price}
          {discountedPrice && <del className="text-grey-60 pl-2 font-normal">{price}</del>}
        </div>
      </div>
    </Link>
  );
}

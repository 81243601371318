"use client";

import dynamic from "next/dynamic";

import { Modal } from "@/common/components/modal";
import { useUI } from "@/common/contexts/ui.context";

const ProductWarningPopup = dynamic(() =>
  import("@/features/product/components/modals/product-warning").then(
    (mod) => mod.ProductWarningPopup,
  ),
);

const UpdateItemConfirmationModal = dynamic(
  () => import("@/features/product/components/modals/update-item-confirmation-modal"),
);
const LoginForm = dynamic(() => import("@/features/auth/components/login-form"));
const ForgotPasswordForm = dynamic(() => import("@/features/auth/components/forgot-password-form"));
const RegistrationSuccessModal = dynamic(
  () => import("@/features/auth/components/registration-success-modal"),
);
const ProductPopup = dynamic(() =>
  import("@/features/product/components/modals/product-popup").then((mod) => mod.ProductPopup),
);
const AddressEdit = dynamic(() => import("@/features/account/components/address-edit"));
const ChangePasswordSuccessModal = dynamic(() =>
  import("@/features/account/components/change-password-success-modal").then(
    (mod) => mod.ChangePasswordSuccessModal,
  ),
);

const DeleteSavedCard = dynamic(
  () => import("@/features/checkout/components/save-cards/delete-saved-card"),
);

const SplitShipmentModal = dynamic(
  () => import("@/features/checkout/components/checkout-split-shipment-modal"),
);

const HardCopiesModal = dynamic(() => import("@/features/basket/modals/basket-hard-copies-modal"));

const ProductionUpgradeWarningModal = dynamic(
  () => import("@/features/basket/modals/production-upgrade-warning-modal"),
);

const AddressDeleteModal = dynamic(
  () => import("@/features/account/components/modals/address-delete-modal"),
);

const VerifyEmailModal = dynamic(() =>
  import("@/features/account/components/modals/verify-email-modal").then(
    (mod) => mod.VerifyEmailModal,
  ),
);

const IncompatibilitiesPopup = dynamic(() =>
  import("@/features/product/components/modals/incompatibilities-popup").then(
    (mod) => mod.IncompatibilitiesPopup,
  ),
);

const CancelOrderConfirmation = dynamic(() =>
  import("@/features/order/components/cancel-order-confirmation").then(
    (mod) => mod.CancelOrderConfirmation,
  ),
);

export function ManagedModal() {
  const { displayModal, closeModal, modalUISettings, modalView } = useUI();

  const modalsWithoutCloseButtons = [
    "ADD_TO_BASKET_CONFIRMATION",
    "ADDRESS_DELETE",
    "INCOMPATIBILITIES",
  ];
  const showCloseButton = !modalsWithoutCloseButtons.includes(modalView);

  return (
    <Modal
      open={displayModal}
      onClose={closeModal}
      showCloseButton={showCloseButton}
      headerText={modalUISettings.header}
    >
      {modalView === "LOGIN_VIEW" && <LoginForm />}
      {modalView === "FORGET_PASSWORD" && <ForgotPasswordForm />}
      {modalView === "PRODUCT_VIEW" && <ProductPopup />}
      {modalView === "PRODUCT_WARNING" && <ProductWarningPopup />}
      {modalView === "ADDRESS_EDIT_FORM" && <AddressEdit />}
      {modalView === "ADDRESS_CREATE_FORM" && <AddressEdit />}
      {modalView === "CONFIRM_CARD_DELETE" && <DeleteSavedCard />}
      {modalView === "HARD_COPIES" && <HardCopiesModal />}
      {modalView === "UPDATE_ITEM_CONFIRMATION" && <UpdateItemConfirmationModal />}
      {modalView === "SPLIT_SHIPMENT" && <SplitShipmentModal />}
      {modalView === "REGISTRATION_SUCCESS" && <RegistrationSuccessModal />}
      {modalView === "PRODUCTION_UPGRADE_WARNING" && <ProductionUpgradeWarningModal />}
      {modalView === "ADDRESS_DELETE" && <AddressDeleteModal />}
      {modalView === "VERIFY_EMAIL" && <VerifyEmailModal />}
      {modalView === "CHANGE_PASSWORD_SUCCESS" && <ChangePasswordSuccessModal />}
      {modalView === "INCOMPATIBILITIES" && <IncompatibilitiesPopup />}
      {modalView === "CANCEL_ORDER_CONFIRMATION" && <CancelOrderConfirmation />}
    </Modal>
  );
}

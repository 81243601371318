"use client";

import { useSession } from "next-auth/react";
import { usePathname } from "next/navigation";
import { useEffect } from "react";
import userflow from "userflow.js";

import { config } from "@/config";
import { useMe } from "@/features/account/hooks/use-me";
import { trackViewPageEvent } from "@/features/analytics";
import { ROUTES } from "@/features/store/settings/routes";

export function RouteChangeListener() {
  const pathname = usePathname();
  const session = useSession();
  const { data: customer } = useMe(session.data);

  useEffect(() => {
    // We should ignore product pages here as we have Viewed Product metric
    if (pathname && !pathname.includes(`${ROUTES.PRODUCT}/`)) {
      trackViewPageEvent({ url: config.NEXT_PUBLIC_BASE_URL + pathname });
    }
  }, [pathname]);

  useEffect(() => {
    if (config.NEXT_PUBLIC_USERFLOW_TOKEN) {
      userflow.init(config.NEXT_PUBLIC_USERFLOW_TOKEN);
    }
  }, []);

  useEffect(() => {
    if (customer?.id) {
      userflow.identify(customer.id, {
        name: [customer.firstName, customer.lastName].filter(Boolean).join(" "),
        email: customer.email,
      });
    } else {
      userflow.identifyAnonymous();
    }
  }, [customer]);

  return null;
}

import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeCardRowComponentSkeleton } from "./TypeCardRowComponent";
import type { TypeImageWithAltTextSkeleton } from "./TypeImageWithAltText";
import type { TypeSeoSkeleton } from "./TypeSeo";

/**
 * Fields type definition for content type 'TypeBlogArticlePage'
 * @name TypeBlogArticlePageFields
 * @type {TypeBlogArticlePageFields}
 * @memberof TypeBlogArticlePage
 */
export interface TypeBlogArticlePageFields {
    /**
     * Field type definition for field 'title' (Title)
     * @name Title
     * @localized true
     */
    title: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'slug' (Slug)
     * @name Slug
     * @localized true
     */
    slug: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'seoMetadata' (SEO Metadata)
     * @name SEO Metadata
     * @localized false
     */
    seoMetadata?: EntryFieldTypes.EntryLink<TypeSeoSkeleton>;
    /**
     * Field type definition for field 'headerImage' (Header Image)
     * @name Header Image
     * @localized false
     * @summary Display the main image in the page header.
     */
    headerImage?: EntryFieldTypes.Boolean;
    /**
     * Field type definition for field 'image' (Image)
     * @name Image
     * @localized false
     */
    image: EntryFieldTypes.AssetLink;
    /**
     * Field type definition for field 'intro' (Intro)
     * @name Intro
     * @localized false
     */
    intro?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'pageContent' (Page Content)
     * @name Page Content
     * @localized false
     */
    pageContent?: EntryFieldTypes.RichText;
    /**
     * Field type definition for field 'category' (Category)
     * @name Category
     * @localized false
     */
    category?: EntryFieldTypes.Array<EntryFieldTypes.Symbol<"Business Resources" | "General" | "Marketing" | "Print Reseller" | "Publishing" | "eCommerce">>;
    /**
     * Field type definition for field 'relatedProducts' (Related Products)
     * @name Related Products
     * @localized false
     */
    relatedProducts?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeCardRowComponentSkeleton>>;
    /**
     * Field type definition for field 'relatedPosts' (Related Posts)
     * @name Related Posts
     * @localized false
     */
    relatedPosts?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeBlogArticlePageSkeleton>>;
    /**
     * Field type definition for field 'postedDate' (Posted Date)
     * @name Posted Date
     * @localized false
     */
    postedDate: EntryFieldTypes.Date;
    /**
     * Field type definition for field 'postedBy' (Posted By)
     * @name Posted By
     * @localized false
     */
    postedBy: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'image_imageWithAltText' (Image using imageWithAltText)
     * @name Image using imageWithAltText
     * @localized false
     */
    image_imageWithAltText: EntryFieldTypes.EntryLink<TypeImageWithAltTextSkeleton>;
}

/**
 * Entry skeleton type definition for content type 'blogArticlePage' (📄 Blog Article Page)
 * @name TypeBlogArticlePageSkeleton
 * @type {TypeBlogArticlePageSkeleton}
 * @author 4cbPwqAt5JzV5Z1U9eQ7iG
 * @since 2024-07-10T09:57:43.481Z
 * @version 3
 */
export type TypeBlogArticlePageSkeleton = EntrySkeletonType<TypeBlogArticlePageFields, "blogArticlePage">;
/**
 * Entry type definition for content type 'blogArticlePage' (📄 Blog Article Page)
 * @name TypeBlogArticlePage
 * @type {TypeBlogArticlePage}
 * @author 4cbPwqAt5JzV5Z1U9eQ7iG
 * @since 2024-07-10T09:57:43.481Z
 * @version 3
 */
export type TypeBlogArticlePage<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeBlogArticlePageSkeleton, Modifiers, Locales>;

export function isTypeBlogArticlePage<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeBlogArticlePage<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'blogArticlePage'
}

import React from "react";

import { tierColors, LoyaltyTier } from "../constants/loyalty";

interface IconProps {
  tier: LoyaltyTier;
  width?: string;
  height?: string;
  textColor?: string;
  iconColor?: string;
}

const LoyaltyTierIcon = ({
  tier,
  width = "48px",
  height = "48px",
  textColor = "#fff",
  iconColor = undefined,
}: IconProps) => {
  const fillColor = iconColor ?? tierColors[tier];

  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 56 56"
      xmlns="http://www.w3.org/2000/svg"
    >
      {tier === LoyaltyTier.BRONZE && (
        <React.Fragment>
          <path
            d="M17.5 56c-2.426 0-8.206-.757-13.578-6.973-5.372-6.221-4.5-15.636-1.698-21.718C4.335 22.723 12.21 7.755 15.752 1.055c.741-1.407 2.749-1.407 3.494 0 3.54 6.7 11.417 21.668 13.528 26.254 2.803 6.082 3.678 15.497-1.698 21.718C25.705 55.247 20.11 56 17.5 56z"
            fill={fillColor}
          />
          <path
            d="M19.85 45.815H9.72V27.148h9.823c3.554 0 5.373 2.267 5.373 4.758 0 2.35-1.455 3.918-3.218 4.282 1.987.308 3.582 2.239 3.582 4.59 0 2.798-1.875 5.037-5.43 5.037zm-6.156-15.28v4.114h4.981c1.344 0 2.183-.868 2.183-2.071 0-1.148-.84-2.043-2.183-2.043h-4.981zm0 7.5v4.394h5.121c1.512 0 2.407-.868 2.407-2.211 0-1.176-.84-2.183-2.407-2.183h-5.121z"
            fill={textColor}
          />
        </React.Fragment>
      )}
      {tier === LoyaltyTier.GOLD && (
        <React.Fragment>
          <path
            d="M17.5 56c-2.426 0-8.206-.757-13.578-6.973-5.372-6.221-4.5-15.636-1.698-21.718C4.335 22.723 12.21 7.755 15.752 1.055c.741-1.407 2.749-1.407 3.494 0 3.54 6.7 11.417 21.668 13.528 26.254 2.803 6.082 3.678 15.497-1.698 21.718C25.705 55.247 20.11 56 17.5 56z"
            fill={fillColor}
          />
          <path
            d="M8.87 36.455c0-5.7 4.321-9.32 9.589-9.32 3.728 0 6.078 1.81 7.456 3.944l-3.188 1.729a5.113 5.113 0 0 0-4.268-2.27c-3.269 0-5.646 2.513-5.646 5.917 0 3.403 2.377 5.916 5.646 5.916 1.648 0 3.107-.676 3.89-1.378v-2.188h-4.808v-3.296h8.59v6.889c-1.837 2.053-4.403 3.403-7.672 3.403-5.268 0-9.59-3.674-9.59-9.346z"
            fill={textColor}
          />
        </React.Fragment>
      )}
      {tier === LoyaltyTier.SILVER && (
        <React.Fragment>
          <path
            d="M17.5 56c-2.426 0-8.206-.757-13.578-6.973-5.372-6.221-4.5-15.636-1.698-21.718C4.335 22.723 12.21 7.755 15.752 1.055c.741-1.407 2.749-1.407 3.494 0 3.54 6.7 11.417 21.668 13.528 26.254 2.803 6.082 3.678 15.497-1.698 21.718C25.705 55.247 20.11 56 17.5 56z"
            fill={fillColor}
          />
          <path
            d="m10.05 43.074 2.113-2.926a7.923 7.923 0 0 0 5.744 2.438c2.113 0 3.142-1.002 3.142-2.031 0-1.355-1.571-1.816-3.657-2.303-2.953-.678-6.746-1.49-6.746-5.527 0-3.007 2.6-5.446 6.854-5.446 2.872 0 5.256.867 7.044 2.52l-2.14 2.818c-1.463-1.355-3.414-1.978-5.175-1.978-1.734 0-2.655.758-2.655 1.842 0 1.22 1.517 1.599 3.603 2.086 2.98.678 6.774 1.572 6.774 5.581 0 3.305-2.358 5.798-7.234 5.798-3.468 0-5.96-1.165-7.667-2.872z"
            fill={textColor}
          />
        </React.Fragment>
      )}
      {tier === LoyaltyTier.PLATINUM && (
        <React.Fragment>
          <path
            d="M17.5 56c-2.426 0-8.206-.757-13.578-6.973-5.372-6.221-4.5-15.636-1.698-21.718C4.335 22.723 12.21 7.755 15.752 1.055c.741-1.407 2.749-1.407 3.494 0 3.54 6.7 11.417 21.668 13.528 26.254 2.803 6.082 3.678 15.497-1.698 21.718C25.705 55.247 20.11 56 17.5 56z"
            fill={fillColor}
          />
          <path
            d="M15.332 45.098h-3.974V26.432h8.731c4.058 0 6.27 2.742 6.27 6.017 0 3.246-2.24 5.96-6.27 5.96h-4.757v6.69zM22.3 32.421c0-1.54-1.175-2.575-2.742-2.575h-4.226v5.15h4.226c1.567 0 2.742-1.008 2.742-2.575z"
            fill={textColor}
          />
        </React.Fragment>
      )}
    </svg>
  );
};

export default LoyaltyTierIcon;

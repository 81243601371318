"use client";

import cn from "clsx";
import { useSession } from "next-auth/react";
import { useTranslations } from "next-intl";

import { LoyaltyTier as LoyaltyTierEnum } from "@/common/constants/loyalty";
import TierIcon from "@/common/icons/tier-icon";
import { useMe } from "../hooks/use-me";

type LoayltyTierProps = {
  readonly className?: string;
  readonly iconOnly?: boolean;
};

export function LoayltyTier({ className, iconOnly = false }: LoayltyTierProps) {
  const t = useTranslations();
  const session = useSession();
  const { data: customer } = useMe(session.data);
  const loyaltyTierKey = customer?.custom?.fields.loyaltyTierKey;

  if (!customer || !loyaltyTierKey || loyaltyTierKey === LoyaltyTierEnum.BLUE) return;

  if (iconOnly)
    return (
      <span className={cn(className)}>
        <TierIcon tier={loyaltyTierKey} width="24px" height="24px" />
      </span>
    );

  return (
    <div
      className={cn(className, "flex pr-3 pl-3 p-1 rounded-md rounded-t-none", {
        "bg-loyalty-gold text-loyalty-goldText": loyaltyTierKey === LoyaltyTierEnum.GOLD,
        "bg-loyalty-bronze text-loyalty-bronzeText": loyaltyTierKey === LoyaltyTierEnum.BRONZE,
        "bg-loyalty-silver text-loyalty-silverText": loyaltyTierKey === LoyaltyTierEnum.SILVER,
        "bg-loyalty-platinum text-loyalty-platinumText":
          loyaltyTierKey === LoyaltyTierEnum.PLATINUM,
      })}
    >
      <TierIcon tier={loyaltyTierKey} width="24px" height="24px" />

      <div>
        <span className="uppercase text-xs font-semibold ml-1">
          {loyaltyTierKey} {t("common.text-prices")}
        </span>
      </div>
    </div>
  );
}
